










































































































































































































@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
.sleep-time {
  display: flex;
  align-items: center;
}

.sleep-time-setter {
  display: inline-flex;
  position: relative;

  --background-color: transparent;
  --base-color: var(--color-white);
  --input-placeholder-color: var(--color-white);
  --font-size: 18px;
}

.action-buttons {
  display: flex;
  flex-grow: 1;
}

.start-time,
.end-time {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-darker);
  color: var(--color-white);
}

.start-time {
  position: relative;
  border-width: 1px 0 1px 1px;
  border-radius: 25px 0 0 25px;
}

.end-time {
  border-width: 1px 1px 1px 0;
  border-radius: 0 25px 25px 0;
}

.start-time,
.end-time {
  border-style: solid;
  border-color: var(--sleep-time-border-color, transparent);
}

.start-time-label,
.start-time-selector,
.end-time-label,
.end-time-selector {
  display: flex;
  justify-content: center;
  font-size: var(--font-size);
}

.start-time-selector,
.end-time-selector {
  position: relative;
  left: 20px;
  width: 110px;
}

.vertical-line {
  flex: 0 0 auto;
  height: 50%;
  width: 1px;
  background-color: var(--color-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.hide {
    display: none;
  }

  &.updated {
    background-color: var(--color-primary-darker);
  }
}

.start-time.highlighted,
.end-time.highlighted {
  background-color: var(--color-white);
  color: var(--color-primary-darker);
  --base-color: var(--color-primary-darker);
  --input-placeholder-color: var(--color-primary-darker);
}

.start-time.highlighted::after {
  background-color: var(--color-primary-darker);
}

.button-group {
  margin-left: 20px;
  align-items: center;
}

.save-button {
  background-color: var(--sleep-time-save-button-background-color, var(--color-primary-dark));
  color: var(--color-white);
  border-radius: 24px;
  padding: 14px 20px;

  &:hover {
    color: var(--color-white);
  }
}

.cancel-button {
  margin-left: 10px;
  vertical-align: middle;
}

.delete-button {
  margin-left: auto;
}

@include breakpoint-sm-down() {
  .sleep-time {
    flex-wrap: wrap;
  }

  .sleep-time-setter {
    flex-basis: 100%;
  }

  .start-time,
  .end-time {
    width: 50%;
    --drop-down-transform: translateX(-60px);
  }

  .action-buttons {
    margin-top: 10px;
    .button-group {
      margin-left: 0;
    }
  }
}

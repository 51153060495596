@import '../../core/variables.scss';

.border-bottom{
  border-bottom:1px solid var(--color-white) !important;
  .theme-employee &{
    display: none;
  }
}

.top-navbar.navbar {
  background: $color-lightblue;
  border-radius: 0;
  padding: 0;
  border: none;
  border-top: 1px solid $color-white;
  align-content: start;
  justify-content: start;
  width: 100%;
  overflow: hidden;

  .notice-boards & {
    position: relative;
  }

  .navbar-nav {
    width: 100%;
    overflow-x: auto;
  }

  @include breakpoint-lg-down() {
    margin-top: -2px;
  }

  @-moz-document url-prefix() {
    .navbar-nav{
      margin-top:0;
    }
  }

  .nav-item {
    margin-bottom: 0;
    &.longtext{
      width: 250px;
      a{
        width: 250px;
      }
    }
    &:hover,&.active {
      .nav-link {
        .theme-employee &{
          background: $color-primary-darker-employee;
        }
        .theme-guardian &{
          background: $color-primary-darker-guardian;
        }
        .theme-child &{
          background: $color-primary-darker-child;
        }
        .theme-admin &{
          background: $color-primary-darker-admin;
        }
        color: $color-white;
      }
    }
    .nav-link {
      min-width:120px;
      padding:14px;
      font-weight:normal;
      max-height:48px;
      min-height:48px;
      border-right:1px solid $color-white;
      background: $color-primary-base-guardian;
      border-radius: 0;
      text-align:center;
      position:relative;
      line-height: 15px;
      color:$color-white;
    }
  }
}

.overview-content{

  padding: 20px 30px;

  .notice-boards &{
    padding: 30px 0px;
  }

  .overview-content-scroll {
    padding-top: 18px;
    padding-bottom: 40vh;

    @include breakpoint-xl-down {
      padding-bottom: 20vh;
    }
  }

  h4{
    display:inline;
  }

  @include breakpoint-lg-down() {
    padding:65px 30px;
  }

  @include breakpoint-xs-down() {
    padding-left: 15px;
    padding-right: 15px;
  }

  .user-box{
    cursor:pointer;
    display: inline-block;
    margin-right:10px;
    position:relative;
    .user-status{
      width:60px;
      height:60px;
      background:$color-picker-button-blue;
      border-radius:50%;
      position: absolute;
      top: -20px;
      left: 10px;
      z-index:1030;
      border:3px solid $color-grey-light;
      i{
        color:$color-white;
        font-size:14px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &.icon-walking-person{
          font-size:22px;
        }
      }
      span{
        position:absolute;
        left:calc(50% - 18px);
        top:calc(50% - 12px);
        color:$color-white;
      }
      &.small{
        i {
          font-size: 22px;
          line-height: 1.4;
        }
      }
      &.absence-status{
        background:$color-grey-medium;
        span,i{
          line-height: 1.5;
          font-size: 22px;
          font-weight: 900;
          color:$color-primary-darker-guardian;
        }
      }
      &.checked-in-status{
        background:$color-primary-dark;
        span,i{
          color:$color-white;
        }
      }
      &.checked-out-status{
        background:$color-grey-light;
        border: 2px solid $color-primary-darker-guardian;
        box-shadow: 0 0 0 2px $color-grey-light;
        span,i{
          color:$color-primary-darker-guardian;
          line-height: 1.1;
        }
      }
      &.ourdoor-status{
        background:$color-primary-darker-child;
        span,i{
          color:$color-white;
          font-size: 25px;
        }
      }
      &.not-present-status{
        background-color: $color-attention;
        span,i{
          font-size: 20px;
          line-height: 1.5;
          color:$color-primary-darker-guardian;
        }
      }
    }
    .user-name{
      background:$color-grey-dark;
      padding:10px 0;
      text-align:center;
      margin-top:-5px;
      color:$color-primary-darker-guardian;
    }
    &.not-present{
      .user-name{
        background:$color-white;
      }
    }
    .user-comment{
      position:absolute;
    }
  }
}

/deep/ .user {
  .user-icon {
    border-radius: 0;
    height: 200px;
    width: 200px;

    @include breakpoint-sm-down() {
      height: 120px;
      width: 120px;
    }

    .not-present & {
      opacity: 0.6;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
      object-fit: contain;
      object-position: 50% 50%;

      .not-present & {
        opacity: 0.6;
      }
    }
  }

  .hide-shortname {
    .shortname {
      display: none;
    }
  }
}

.el-select-dropdown{
  .el-select-dropdown__list{
    padding: 0 0 2px;
  }
  .el-select-dropdown__item{
    text-align:right;
    color:$color-darkblue;
    font-size: 16px;

    &:last-child {
      padding-bottom: 20px;
    }
  }
  .el-scrollbar__wrap {
    padding-bottom: 17px;
    overflow: hidden;
  }
  .form-control{
    border: 1px solid $color-grey-base;
  }
}

.align-left{
  .el-select-dropdown__item{
    text-align: left;
  }
}

.el-select {
  /deep/ .el-input.is-disabled {
    .el-input__icon {
      visibility: hidden;
    }
  }
}

.aula-presence-overview {
  --btn-link-color: var(--color-white);
  --sleep-time-header-bottom-border: 1px solid var(--color-white);
  --sleep-time-header-font-size: 18px;
  --sleep-time-header-font-weight: 400;
  --sleep-time-header-text-tranform: none;
  --sleep-time-save-button-background-color: var(--color-primary-darker)
}

.details-bottom {
  --font-size: 24px;
  --base-color: var(--color-white);
  --input-placeholder-color: var(--color-white);
}

/deep/.aula-modal-container{
  .modal-aula{
    min-width:80%;
    top:10vh;
    --aula-modal-max-height: 80vh;

    @include breakpoint-lg-down() {
      width: 100vw !important;
      max-width: 100vw !important;
      height: 100% !important;
      --aula-modal-max-height: 100vh;
      top:0;
    }
    .modal-body{
      padding:0;
      color:$color-white;
      font-size:24px;
      background-color: var(--color-primary-darker);

      .alert.alert-danger{
        font-size:16px;
        color:$color-white;
      }
      .el-select, .vue__time-picker{
        .el-select__caret{
          color:$color-white;
        }
        input.el-input__inner{
          color:$color-white;
          font-size:24px;
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $color-white !important;
            opacity: 1; /* Firefox */
            font-size:24px;
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $color-white !important;
            font-size:24px;
          }

          &::-ms-input-placeholder { /* Microsoft Edge */
            color: $color-white !important;
            font-size:24px;
          }
        }
      }

      .details-bottom {
        input.el-input__inner, .display-time {
          background: transparent;
          border:0;
          margin-bottom:0;
          text-align:right;
          padding: 0 40px 0 0;
        }

        .dropdown {
          right: 0;
          left: unset;
        }
      }
      .align-left {
        input.el-input__inner{
          text-align: left;
        }
      }
      .details-top{
        padding:60px 100px 20px 100px;
        @include breakpoint-lg-down(){
          padding:20px;
        }
        background-color:$color-primary-base-guardian;



        .el-select{
          input.el-input__inner{
            font-size:18px;
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              font-size:18px;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              font-size:18px;
            }
            &::-ms-input-placeholder { /* Microsoft Edge */
              font-size:18px;
            }
          }
        }


      }

      .details-bottom {
        position: sticky;
        bottom: 0;
        padding: 40px;
        z-index: $modal-sticky-header-z-index;
        background-color: var(--color-primary-darker-guardian);

        i {
          color: var(--color-primary-base-guardian);
        }

        .btn-save-comment {
          color: var(--color-white);
        }

        label {
          text-transform: unset;
          font-weight: normal;
        }

        input.form-control {
          background: transparent;
          color: var(--color-white);
          border: 0;
          font-size: 24px;
          padding: 0;
          margin-bottom: 0;
          text-align: right;

          &.dark-bg {
            background: var(--color-primary-dark-guardian);
            padding: 7px 18px;
          }

          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: var(--color-white) !important;
            opacity: 1; /* Firefox */
            font-size: 24px;
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: var(--color-white) !important;
            font-size: 24px;
          }

          &::-ms-input-placeholder { /* Microsoft Edge */
            color: var(--color-white) !important;
            font-size: 24px;
          }

          &.input-comment {
            text-align: left;
          }
        }

        .btn-link {
          color: var(--color-white);
        }

        .btn-white {
          color: var(--color-primary-darker-guardian);
        }

        .el-date-editor {
          width: 100%;

          .el-input__icon {
            color: var(--color-white) !important;
          }

          input.el-input__inner {
            background: var(--color-primary-dark-guardian);
            color: var(--color-white) !important;

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: var(--color-white) !important;
              opacity: 1; /* Firefox */
              font-size: 18px;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: var(--color-white) !important;
              font-size: 18px;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
              color: var(--color-white) !important;
              font-size: 18px;
            }
          }
        }
      }
      @include breakpoint-lg-down() {
        .details-bottom {
          position: static;
          z-index: unset;
        }
      }
    }
  }
}

.department-select{
  position: fixed;
  top: calc(var(--menu-top-bar-height)/2);
  right: 5px;
  transform: translateY(-50%);
  z-index: $department-select-z-index;
  color: $color-white;
  display: flex;
  align-items: center;
  gap: 10px;

  .aula-select {
    width: Min(50vw, 400px);
  }

  .notice-boards &{
    position: absolute;
    top: 15px;
    right: 20px;
    color:$color-primary-darker-guardian;
    @include breakpoint-xl-down() {
      position: unset;
    }
  }
  label{
    margin-bottom: 0px;
  }
}
.new-pickup {
  padding: 15px 25px;
  .pickup-responsible-save {
    float: right;
    margin-bottom: -60px;
    padding: 14px 20px !important;
  }
  .new-pickup-responsible{
    border-radius: 0px !important;
    background-color: $color-grey-light !important;
    &.show-button{
      width: calc(100% - 85px);
    }
  }
}
.small-text{
  font-size:18px;
  .theme-employee &{
    font-size:16px;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.child-header{
  display: flex;
  flex-wrap: wrap;
  .child{
    padding: 15px 10px 15px 15px;
    border: 1px solid $color-grey;
    min-width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-weight: 700;
    cursor:pointer;
    @include breakpoint-lg-down(){
      margin-right: 0px;
      display: block;
      width: 100%;
    }
    .remove-child{
      float: right;
      margin-left: 10px;
      i{
        border-radius: 50%;
        padding: 5px;
        background-color: $color-darkblue;
        color: $color-white;
        font-size: 10px;
        &:hover{
          background-color: $color-grey;
        }
      }
    }
  }
  .user-photo{
    flex: 1;
    width: 200px;
    height: 200px;
    /deep/ .user-icon{
      border: 15px solid $color-primary-light-child;
      .theme-employee &{
        border: unset;
      }

      .shortname {
        font-size: 1.2em;
      }
    }
    @include breakpoint-sm-down(){
      width: 120px;
      height: 120px;
    }
  }
  .child-check-in{
    position: relative;
    width: calc(100% - 230px);
    height: 200px;
    @include breakpoint-sm-down(){
      width: calc(100% - 140px);
      height: 120px;
    }
    .child-name{
      position: absolute;
      bottom: 0px;
      font-size: 24px;
      .btn{
        margin-top: 30px;
      }
      @include breakpoint-lg-down(){
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  .update-status{
    width: 100%;
    display: inline-block;
  }
}
.btn-update-status{
  padding:17px 100px;
  margin-bottom: 5px;
  font-size:18px;
  font-weight: 700;
  text-transform:uppercase;
  white-space: nowrap;
  @include breakpoint-sm-down(){
    padding:15px 50px;
  }
}

.presence-icons{
  display:flex;
  margin-top:20px;
  gap: 20px;
  flex-flow: row wrap;

  .presence-icon-container {
    max-width: 60px;
    margin-bottom: 8px;
  }

  .icons-description{
    margin-top: 5px;
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-size: 11px;
  }

  .icon-circle {
    width: 60px;
    height: 60px;
    position: relative;
    border: 3px solid var(--color-primary-light-child);
    --color: var(--color-white);
    --background-color: unset;

    .aula-icon {
      font-size: 22px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .theme-employee & {
      border: 1px solid var(--color-primary-darker-employee);
      --color: var(--color-primary-darker-employee);
      --background-color: var(--color-white);
    }

    &.disabled,
    &.disabled + .icons-description{
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.active {
      border: 0;
      --background-color: var(--color-primary-darker-guardian);
      --color: var(--color-white);

      .theme-employee & {
          --background-color: var(--color-primary-darker-employee);
        }
    }
  }

  .activity-comment{
    margin-top: 15px;
    margin-left: 10px;
    width: 40vw;
    font-size: 12px;
    text-align: left;
  }
}

.specific-open-hours-alert-container {
  .aula-alert {
    position: relative;
    display: flex;
    line-height: 20px;
    background: $color-grey-base;
    min-height: 50px;
    border-radius: 5px;
    .alert-text {
      flex: 1;
      margin: auto 15px;
      font-size: 1rem;
      color: $color-primary-darker-guardian;
      font-weight: bold;
    }
    .icon-box-exclamation {
      width: 50px;
      max-height: 100%;
      background-color: $color-grey-dark;
      border-radius: 5px 0 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      .circle {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-primary-darker-guardian;

        i {
          font-size: 15px;
        }
      }
    }
  }
}

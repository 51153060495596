



























































































































































































































@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';

.sleep-time-list {
  font-size: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    --background-color: transparent;
    border-bottom: var(--sleep-time-header-bottom-border, unset);
    font-size: var(--sleep-time-header-font-size, 16px);
    font-weight: var(--sleep-time-header-font-weight, 700);
    text-transform: var(--sleep-time-header-text-tranform, uppercase);
  }
}

.list-container {
  --modal-inner-input-background-color: transparent;
}














































































































































































































@import '../../assets/scss/core/variables';
@import '../../assets/scss/core/breakpoints';
.add-vacation-container {
  flex: 1;
  @include breakpoint-lg-down() {
    flex: unset;
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }
  .cancel-vacation-button {
    color: $color-white;
    padding: 14px 44px;
    .theme-employee & {
      color: $color-grey;
    }
  }
  input.form-control {
    width: 100%;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 18px;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 18px;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      font-size: 18px;
    }
  }
  @include breakpoint-lg-down() {
    margin-top: 20px;
  }
}
